<template>
  <div class="footer-checkout-container" v-bind:class="rootClassName">
    <img :alt="image_alt" :src="image_src" class="footer-checkout-image" />
    <div class="footer-checkout-container1">
      <div class="footer-checkout-container2">
        <span class="footer-checkout-text">
            {{$t_('copyright').replace('2021', new Date().getFullYear())}}
        </span>
        <div class="footer-checkout-container3">
          <img
            :alt="image_alt1"
            :src="image_src1"
            class="footer-checkout-image1"
          />
          <img
            :alt="image_alt2"
            :src="image_src2"
            class="footer-checkout-image2"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterCheckout',
  props: {
    rootClassName: String,
    image_alt: {
      type: String,
      default: 'image',
    },
    image_src: {
      type: String,
      default: '/playground_assets/padrao-1000h.png',
    },
    image_src2: {
      type: String,
      default: '/playground_assets/imply122x30-1200h.png',
    },
    image_src1: {
      type: String,
      default: '/playground_assets/eleventickets122x30-1200h.png',
    },
    image_alt1: {
      type: String,
      default: 'image',
    },
    image_alt2: {
      type: String,
      default: 'image',
    },
  },

  data() {
    return {
      
    }
  },
}
</script>

<style scoped>
.footer-checkout-container {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.footer-checkout-image {
  width: 100%;
  height: 4px;
  object-fit: cover;
}
.footer-checkout-container1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-gray-black);
}
.footer-checkout-container2 {
  flex: 0 0 auto;
  width: 991px;
  display: flex;
  padding: 15px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.footer-checkout-text {
  color: var(--dl-color-gray-700);
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.footer-checkout-container3 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 15px;
  flex-direction: row;
  padding-bottom: 15px;
  justify-content: center;
}
.footer-checkout-image1 {
  width: 100px;
  object-fit: cover;
  margin-left: 5px;
  margin-right: 5px;
}
.footer-checkout-image2 {
  width: 100px;
  object-fit: cover;
  margin-left: 5px;
  margin-right: 5px;
}
.rootClassName {
  margin-bottom: 60px;
}
@media(max-width: 991px) {
  .footer-checkout-container2 {
    width: 100%;
  }
}
</style>
